<template>
  <div class="vx-row w-full justify-center mx-auto extraServ">
    <table>
      <tr class="HeaderTableColor">
        <th class="font-bold text-left">Extra Service</th>
        <th class="font-bold text-left">Price</th>
      </tr>
      <tr>
        <td class="text-left">
          <vs-checkbox v-model="checkBox1">Translation services </vs-checkbox>
        </td>
        <td class="text-left">150,00$</td>
      </tr>
      <tr>
        <td class="text-left">
          <vs-checkbox v-model="checkBox1"
            >Extra days hospitalization (4 extra days)
          </vs-checkbox>
        </td>
        <td class="text-left">800,00$</td>
      </tr>
      <tr>
        <td class="font-bold text-right">
          <vs-button class="text-lg mx-1">{{ $t("Confirm") }}</vs-button>
        </td>
        <td class="font-bold text-left"></td>
      </tr>
    </table>
  </div>
</template>
<script>
import modulePatient from "@/store/Patient/modulePatient.js";
import { domain } from "@/gloabelConstant.js";
import axios from "@/axios.js";

export default {
  data() {
    return {
      baseURL: domain,
      Model: [],
    };
  },
  methods: {},
};
</script>
<style>
#extraServ table {
  border-collapse: collapse;
  border-radius: 2em;
  overflow: hidden;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 22px#00000029;
}
#extraServ th,
#extraServ td {
  border: 1px solid #e2e0e0;
  border-collapse: collapse;
  padding: 1.8rem;
  font-size: 1.2rem !important;
}

#extraServ .background {
  color: rgb(255, 9, 9);
}
.material-icons 
{
  font-family: 'Material Icons'!important;
}
.material-icons  :lang(en)
{
  font-family:unset
}
</style>
